import * as Sentry from '@sentry/angular';

import { getActionsToAttachSentryEvent } from './sentry-meta-reducer';
import { applyTransformers } from './utils';

const eventTransformers = [attachActions];

export function beforeSend(event: Sentry.ErrorEvent) {
  return applyTransformers(event, eventTransformers);
}

function attachActions(event: Sentry.ErrorEvent): Sentry.ErrorEvent {
  return {
    ...event,
    extra: {
      ...event.extra,
      actions: getActionsToAttachSentryEvent(),
    },
  };
}
