export function removeEmptyValues<T extends object>(obj: T): Partial<T> {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value === null || value === undefined || value === '') {
      return acc;
    }

    if (Array.isArray(value)) {
      const filteredArray = value.filter(
        (item) => item !== null && item !== undefined && item !== '',
      );
      if (filteredArray.length) {
        return { ...acc, [key]: filteredArray };
      }
      return acc;
    }

    if (typeof value === 'object') {
      const filteredObject = removeEmptyValues(value);
      if (Object.keys(filteredObject).length) {
        return { ...acc, [key]: filteredObject };
      }
      return acc;
    }

    return { ...acc, [key]: value };
  }, {});
}
