import { Filter, FilterType } from 'graphql-api/gql-api-interfaces';

export function getMaxDisplayValue(filter: Filter | undefined) {
  if (
    filter?.type === FilterType.Range &&
    filter.values?.[0].displayName.endsWith('+')
  ) {
    const parts = filter.values?.[0].displayName.split('-');
    return parseInt(parts[1], 10);
  }
  return undefined;
}
